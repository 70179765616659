import React from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiPageSection,
    EuiPageHeader,
    EuiPageHeaderSection,
} from '@elastic/eui';

const CustomPage = ({ children, HeaderLeft, HeaderRight }) => (
    <EuiPage paddingSize="m" restrictWidth={800} >
        <EuiPageBody component="div" >
            <EuiPageSection color="plain" >
                {(HeaderLeft || HeaderRight) && (
                    <EuiPageHeader responsive={false} >
                        {HeaderLeft && <EuiPageHeaderSection>{HeaderLeft}</EuiPageHeaderSection>}

                        {HeaderRight && <EuiPageHeaderSection>{HeaderRight}</EuiPageHeaderSection>}
                    </EuiPageHeader>
                )}
                <EuiPageSection>
                    {children}
                </EuiPageSection>
            </EuiPageSection>
        </EuiPageBody>
    </EuiPage>
)

export default CustomPage;
