import React, { Fragment } from 'react';
import { EuiText, EuiFlexGroup, EuiFlexItem, EuiHealth, EuiSkeletonText, EuiLink, EuiHorizontalRule } from '@elastic/eui';
import { useHistory } from 'react-router';

const Link = ({ to, title }) => {
    const history = useHistory();

    return <EuiLink onClick={() => history.push(to)} >{title}</EuiLink>;
}

const StatusRow = ({ title, subtitle, status, loading = false, linkTo, extraItems, showRule = false }) => {

    return (
        <>
            <EuiFlexGroup justifyContent="spaceBetween" alignItems="center" responsive={false} >
                <EuiFlexItem grow={5} >
                <EuiText>
                    <p style={subtitle ? { marginBottom: 0 } : null} >
                        {linkTo ? <Link to={linkTo} title={title} /> : title}
                    </p>
                    {subtitle && <EuiText size="xs" ><p>({subtitle})</p></EuiText>}
                </EuiText>
            </EuiFlexItem>

                <EuiFlexItem grow={5} >
                    <EuiFlexGroup alignItems="center" justifyContent="flexEnd" >
                        {extraItems && (
                            <EuiFlexItem>
                                {extraItems}
                            </EuiFlexItem>
                        )}

                        <EuiFlexItem grow={false} style={{ display: 'flex', justifyContent: "space-between" }} >
                            {loading
                                ? (
                                    <EuiSkeletonText lines={1} style={{ width: 100 }} />
                                ) : (
                                    <Fragment>
                                        {status === 200
                                            ? (
                                                <EuiHealth color="success">Operational</EuiHealth>
                                            ) : (
                                                <EuiHealth color="danger">Failure</EuiHealth>
                                            )}
                                    </Fragment>
                                )}
                        </EuiFlexItem>
                    </EuiFlexGroup>

                </EuiFlexItem>
            </EuiFlexGroup>

            {showRule && <EuiHorizontalRule margin="s" />}
        </>
    )
}

export default StatusRow;
