
import React, { useState } from 'react';
import {
    EuiButton,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
} from '@elastic/eui';

import { auth } from "../../../helpers/firebase";

const NameForm = ({ manufacturerUrl, onSuccess }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [name, setName] = useState("");

    const resetError = () => setError(null)

    const handleSubmit = async e => {
        e.preventDefault();

        if (!loading) {
            setLoading(true)
            setError(null)

            try {
                await auth.currentUser.updateProfile({ displayName: name });
                await auth.updateCurrentUser(auth.currentUser);
                if (onSuccess) onSuccess();
            } catch (e) {
                console.log(e)
                setError("Something went wrong")
            }

            setLoading(false)
        }
    }

    const helpText = `We would like to know your name before you send an access request to ${manufacturerUrl}`

    return (
        <EuiForm component="form" onSubmit={handleSubmit} isInvalid={error !== null} error={error} >
            <EuiFormRow label="Name" helpText={helpText} isInvalid={error !== null} >
                <EuiFieldText
                    autoFocus
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiSpacer />

            <EuiButton
                type="submit"
                fill
                disabled={loading}
                isLoading={loading}
            >
                Save
            </EuiButton>
        </EuiForm>
    );

}

export default NameForm;