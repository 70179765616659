import React, { Fragment } from 'react';

import {
    EuiButtonEmpty,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiText,
    EuiSpacer,
    EuiCodeBlock,
} from '@elastic/eui';

const StatusModal = ({ closeModal, statusObject }) => {
    return (
        <EuiModal onClose={closeModal} >
            <EuiModalHeader>
                <EuiModalHeaderTitle>{statusObject.status}</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                {statusObject.url && (
                    <Fragment>
                        <EuiText><h3>Url</h3></EuiText>
                        <p>{statusObject.url}</p>

                        <EuiSpacer />
                    </Fragment>
                )}

                {statusObject.data && (
                    <Fragment>
                        <EuiText><h3>Data</h3></EuiText>

                        <EuiCodeBlock isCopyable language="js" color="dark" >
                            {JSON.stringify(statusObject.data, null, 4)}
                        </EuiCodeBlock>

                        <EuiSpacer />
                    </Fragment>
                )}

                {statusObject.error && (
                    <Fragment>
                        <EuiText><h3>Feilmelding</h3></EuiText>

                        <EuiCodeBlock isCopyable language="js" color="dark" >
                            {JSON.stringify(statusObject.error, null, 4)}
                        </EuiCodeBlock>

                        <EuiSpacer />
                    </Fragment>
                )}
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButtonEmpty onClick={closeModal}>Lukk</EuiButtonEmpty>
            </EuiModalFooter>
        </EuiModal>
    );
};

export default StatusModal;
