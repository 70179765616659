import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/functions";

let firebaseConfig;
const useDev = process.env.REACT_APP_FIREBASE_ENV === "dev" || process.env.NODE_ENV === "development";

if (useDev) {
    firebaseConfig = {
        apiKey: "AIzaSyDTqii3qT-SqHp3_dC7OWrq5vudi5ylOG4",
        authDomain: "octaos-status-dev.firebaseapp.com",
        databaseURL: "https://octaos-status-dev.firebaseio.com",
        projectId: "octaos-status-dev",
        storageBucket: "octaos-status-dev.appspot.com",
        messagingSenderId: "98401136495",
        appId: "1:98401136495:web:b61cb312694017c2ebc29a"
    }
} else {
    firebaseConfig = {
        apiKey: "AIzaSyCwxWN2aS8QtryLAvXBnKH8a_4VvzkEYoU",
        authDomain: "octaos-status.firebaseapp.com",
        databaseURL: "https://octaos-status.firebaseio.com",
        projectId: "octaos-status",
        storageBucket: "octaos-status.appspot.com",
        messagingSenderId: "183821836136",
        appId: "1:183821836136:web:9a71ef955f44e52fa700dd"
    }
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const functions = firebaseApp.functions("europe-west2");

if (window.location.hostname === "localhost") {
    console.log("localhost detected!");
    db.settings({ host: "localhost:8080", ssl: false });
    functions.useFunctionsEmulator("http://localhost:5001");
}

export { db, auth, functions, firebase };
