import React, { Fragment } from 'react';
import { EuiBasicTable } from '@elastic/eui';

import usePagination from '../../../../helpers/usePagination';
import useCollection from '../../../../helpers/useCollection';

const UsersView = () => {
    const [loading, error, users] = useCollection("users");
    const { pagination, onTableChange, pageItems } = usePagination(users);

    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>
    if (!loading && !users) return <p>Brukere ble ikke funnet</p>

    const columns = [
        {
            field: 'name',
            name: 'Navn',
        },
        {
            field: 'manufacturers',
            name: 'Produsenter',
            render: (manufacturers, user) => user.isAdmin ? "(admin)" : Array.isArray(manufacturers) ? manufacturers.join(", ") : "INGEN",
        },
        {
            field: 'phoneNumber',
            name: 'Nummer',
        },
        {
            field: 'id',
            name: 'Id',
        },
        {
            name: '',
            actions: [],
        }
    ];

    return (
        <Fragment>
            <EuiBasicTable
                items={loading ? [] : pageItems}
                rowHeader="id"
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={onTableChange}
            />
        </Fragment>
    );
}

export default UsersView;
