import { useState } from 'react';

const usePagination = (items, pageSizeOptions = [10, 25, 50]) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(pageSizeOptions[0]);

    const onTableChange = ({ page = {} }) => {
        const { index: pageIndex, size: pageSize } = page;

        setPageIndex(pageIndex);
        setPageSize(pageSize);
    };

    const pagination = {
        pageIndex,
        pageSize,
        totalItemCount: items ? items.length : 0,
        pageSizeOptions,
        hidePerPageOptions: false,
    };

    const startIndex = pageIndex * pageSize;
    const pageItems = items ? items.slice(startIndex, startIndex + pageSize) : [];

    return { pagination, onTableChange, pageItems };
}

export default usePagination;
