import { useState, useEffect } from 'react';

import { db } from "../helpers/firebase";

const useDocument = (collection, document) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    useEffect(() => {
        setIsLoading(true);
        setError(null);
        setData(null);

        const unsubscribe = db.collection(collection).doc(document).onSnapshot((snap) => {
            const _data = snap.exists ? { id: snap.id, ...snap.data() } : null;

            setData(_data);
            setIsLoading(false);
        }, err => {
            setError(err);
            setIsLoading(false);
        });

        return (() => {
            unsubscribe();
        })
    }, [collection, document])

    return [isLoading, error, data];
}

export default useDocument;
