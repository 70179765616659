import { useState, useEffect } from 'react';

import { db } from "../helpers/firebase";

const useCollection = (collection, orderBy = null, limit = null) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        setError(null);
        setData(null);

        let ref = db.collection(collection);

        if (orderBy) ref = ref.orderBy(orderBy, "desc");
        if (limit) ref = ref.limit(limit);

        const unsubscribe = ref.onSnapshot((snap) => {
            const docs = snap.docs;

            const _data = docs.map(doc => ({ id: doc.id, ...doc.data() }));

            setData(_data);
            setIsLoading(false);
        }, err => {
            setError(err);
            setIsLoading(false);
        });

        return (() => {
            unsubscribe();
        })
    }, [collection, orderBy, limit])

    return [isLoading, error, data];
}

export default useCollection;
