import { db } from './firebase';

const _hasAccess = async (userId, manufacturerUrl) => {
    const doc = await db.collection("users").doc(userId).get();

    if (!doc || !doc.exists) return false;

    return doc.data() && Array.isArray(doc.data().manufacturers) && doc.data().manufacturers.some(man => man === manufacturerUrl);
}

export const sendAccessRequest = async (user, manufacturerUrl) => {
    const { uid: userId, phoneNumber, displayName } = user;
    const collection = `userAccessRequests/${userId}/requests`;

    const hasAccess = await _hasAccess(userId, manufacturerUrl);

    if (hasAccess) return "has_access";

    await db.collection("userAccessRequests").doc(userId).set({ userId, phoneNumber, name: displayName }, { merge: true }); // Make sure request is visible in Firebase console
    await db.collection(collection).doc(manufacturerUrl).set({ userId, phoneNumber, name: displayName });

    return "success";
}