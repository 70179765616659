import React, { useState } from 'react';

import {
    EuiButton,
    EuiPopover,
    EuiForm,
    EuiFormRow,
    EuiFieldText,
    EuiSpacer,
} from '@elastic/eui';

const ManufacturerSiteFrom = ({ site, onAdd, onEdit, getIsUnique, saving = false }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const [name, setName] = useState(site?.name ? site.name : "");
    const [url, setUrl] = useState(site?.url ? site.url : "");

    const isEdit = !!site;
    const isUnique = name.length > 0 && getIsUnique(name);
    const isValid = name.length > 0 && url.length > 0 && isUnique;

    const handleSave = () => {
        if (isEdit) onEdit(site.name, { url, name });
        else onAdd({ url, name });

        handleClick();
    }

    const handleClick = () => {
        if (!isPopoverOpen) {
            setName(site?.name ? site.name : "");
            setUrl(site?.url ? site.url : "");
        }

        setIsPopoverOpen(!isPopoverOpen)
    }

    const addButton = (
        <EuiButton
            iconSide="left"
            iconType="listAdd"
            onClick={handleClick}
            disabled={saving}
            isLoading={saving}
        >
            Legg til
        </EuiButton>
    );

    const editButton = (
        <EuiButton size="s" fill onClick={handleClick} disabled={saving} isLoading={saving} >
            <p>{site ? `${site.name} - ${site.url}` : ""}</p>
        </EuiButton>
    );

    const form = (
        <EuiForm>
            <EuiFormRow label="Navn" helpText="*Må være unik" >
                <EuiFieldText name="name" value={name} onChange={e => setName(e.target.value)} />
            </EuiFormRow>

            <EuiFormRow label="Url">
                <EuiFieldText name="url" value={url} onChange={e => setUrl(e.target.value)} />
            </EuiFormRow>

            <EuiSpacer />

            <EuiButton fullWidth disabled={!isValid} onClick={handleSave} >Lagre</EuiButton>
        </EuiForm>
    );

    return (
        <EuiPopover
            id="formPopover"
            ownFocus
            button={isEdit ? editButton : addButton}
            isOpen={isPopoverOpen}
            closePopover={() => setIsPopoverOpen(false)}>
            <div style={{ width: '300px' }}>{form}</div>
        </EuiPopover>
    )
}

export default ManufacturerSiteFrom;
