
import React, { Fragment } from 'react';
import { EuiText, EuiHorizontalRule, EuiFlexGroup, EuiFlexItem, EuiToolTip } from '@elastic/eui';

const translations = {
    shNew: "Create quote",
    shUpdate: "Update quote",
    slAdd: "Add line",
    slGetDrawing2D: "Get drawing",
    slUpdateProperties: "Update width",
    slSaveCurrent: "Save",
    ExecuteReports: "Generate report",
    slDeleteCurrent: "Delete line",
    setQuoteDelete: "Delete quote",
}

const ResultRender = ({ title, column1, column2, Component, alignText = true }) => {
    const text1 = typeof column1 === "number" ? `${column1.toFixed(0)}` : column1;
    const text2 = typeof column2 === "number" ? `${column2.toFixed(0)}` : column2;

    let color1 = "default";
    // let color2 = "default";

    let difference;

    if (typeof column1 === "number" && typeof column2 === "number") {
        color1 = column1.toFixed(0) === column2.toFixed(0) ? "warning" : column1 > column2 ? "danger" : "secondary";
        // color2 = column2 === column1 ? "warning" : column2 > column1 ? "secondary" : "danger";

        difference = (column1.toFixed(0) - column2.toFixed(0)).toFixed(0);
    }

    if (translations[title]) {
        title = <EuiToolTip content={title} ><EuiText>{translations[title]}</EuiText></EuiToolTip>;
    }

    return (
        <EuiFlexGroup justifyContent="spaceBetween" alignItems="center" responsive={false} >
            <EuiFlexItem>
                <EuiFlexGroup alignItems="center" gutterSize="none" responsive={false} >
                    <EuiFlexItem>
                        <EuiText textAlign={alignText ? "left" : null} >
                            {Component ? <Component>{text1}</Component> : text1}
                        </EuiText>
                    </EuiFlexItem>

                    {difference && (
                        <EuiFlexItem>
                            <EuiText color={color1} >
                                {`(${difference > 0 ? `+${difference}` : difference})`}
                            </EuiText>
                        </EuiFlexItem>
                    )}
                </EuiFlexGroup>

            </EuiFlexItem>

            <EuiFlexItem>
                <EuiText textAlign={alignText ? "center" : null} >
                    {Component ? <Component>{title}</Component> : title}
                </EuiText>
            </EuiFlexItem>

            <EuiFlexItem>
                <EuiText textAlign={alignText ? "right" : null} >
                    {Component ? <Component>{text2}</Component> : text2}
                </EuiText>
            </EuiFlexItem>
        </EuiFlexGroup>
    )
}

const ResultRow = ({ resultManufacturer, resultsReference }) => {
    const { error, func, time } = resultManufacturer;

    const referenceResult = resultsReference.find(res => res.func === func);
    const column2 = referenceResult ? referenceResult.error ? "Error" : referenceResult.time : "Not found";

    return <ResultRender title={func} column1={error ? "Error" : time} column2={column2} />;
}

const ResultView = ({ speedTest, manufacturerUrl }) => (
    <Fragment>
        <ResultRender
            column1={`${manufacturerUrl}`}
            column2="reference"
            Component={({ children }) => <h2>{children}</h2>}
        />

        <ResultRender
            column1="(ms)"
            column2="(ms)"
            Component={({ children }) => <small>{children}</small>}
        />

        <ResultRender
            title="Total Time"
            column1={speedTest.result.resultManufacturer.totalTime}
            column2={speedTest.result.resultReference.totalTime}
            Component={({ children }) => <h3>{children}</h3>}
        />

        <EuiHorizontalRule margin="xl" />

        {speedTest.result.resultManufacturer.results.map(funcResult => (
            <Fragment key={funcResult.func} >
                <ResultRow
                    resultManufacturer={funcResult}
                    resultsReference={speedTest.result.resultReference.results}
                />

                <EuiHorizontalRule />
            </Fragment>
        ))}
    </Fragment>
);

export default ResultView;