import React from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import App from "./App";

import "@elastic/eui/dist/eui_theme_dark.css";
import { EuiProvider } from "@elastic/eui";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <EuiProvider colorMode="dark">
      <App />
    </EuiProvider>
  </React.StrictMode>
);
