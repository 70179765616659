import { useState } from 'react';
import { useHistory } from 'react-router';

const useNav = () => {
    const history = useHistory()
    const [currentPath, setCurrentPath] = useState(history.location.pathname);

    const isModifiedEvent = (event) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

    const isLeftClickEvent = (event) => event.button === 0

    const onClick = (event, to) => {
        if (event.defaultPrevented) {
            return
        }

        if (event.target.getAttribute('target')) {
            return
        }

        if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
            return
        }

        event.preventDefault()

        history.push(to)

        setCurrentPath(to)
    }

    history.listen(location => setCurrentPath(location.pathname));

    return [onClick, currentPath];
}

export default useNav
