import React, { Fragment } from 'react';
import useCollection from '../../../helpers/useCollection';
import { getAllSystemsOperational } from '../../../helpers/utils';
import StatusRow from '../../../components/StatusRow';

const ManufacturerStatus = ({ manufacturer }) => {
    const [loading, error, statusLog] = useCollection(`manufacturers/${manufacturer.id}/statusLogs`, "createdAt");
    const latestStatus = statusLog ? statusLog[0] : null;
    const allSysyemsOperational = latestStatus ? getAllSystemsOperational(latestStatus) : false;

    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>

    const status = allSysyemsOperational ? 200 : 500;

    return (
        <Fragment>
            <StatusRow
                title={manufacturer.name}
                status={status}
                loading={loading}
                linkTo={`/admin/manufacturers/${manufacturer.id}${status === 200 ? "" : "/logs"}`}
            />
        </Fragment>
    );
}

export default ManufacturerStatus;
