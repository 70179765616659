import React from 'react';

import {
    EuiButtonEmpty,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiText,
} from '@elastic/eui';

const TextModal = ({ closeModal, text, title }) => {
    return (
        <EuiModal onClose={closeModal} >
            <EuiModalHeader>
                <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <EuiText><pre>{text}</pre></EuiText>
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButtonEmpty onClick={closeModal}>Lukk</EuiButtonEmpty>
            </EuiModalFooter>
        </EuiModal>
    );
};

export default TextModal;