import React, { Fragment, useEffect, useState } from 'react';
import { EuiBasicTable, EuiLink } from '@elastic/eui';

import usePagination from '../../../../helpers/usePagination';
import useCollection from '../../../../helpers/useCollection';
import { db } from '../../../../helpers/firebase';

const AccessRequests = () => {
    const [accessRequests, setAccessRequests] = useState([]);
    const [refreshToggle, setRefreshToggle] = useState(false);
    const [loading, error, userAccessRequests] = useCollection("userAccessRequests");
    const { pagination, onTableChange, pageItems } = usePagination(accessRequests);

    useEffect(() => {
        const fetchAccessRequests = async () => {
            const promises = userAccessRequests.map(async userAccessRequest => {
                const uid = userAccessRequest.id;
                const _accessRequests = await db.collection(`userAccessRequests/${uid}/requests`).get();

                return _accessRequests.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            })

            const _accessRequests = await Promise.all(promises);
            const accessRequests = [];

            _accessRequests.forEach(userAccessRequests => {
                if (Array.isArray(userAccessRequests)) {
                    userAccessRequests.forEach(accessRequest => {
                        accessRequests.push(accessRequest);
                    })
                }
            })

            setAccessRequests(accessRequests);
        }

        if (Array.isArray(userAccessRequests) && userAccessRequests.length > 0) {
            fetchAccessRequests();
        } else {
            setAccessRequests([]);
        }
    }, [userAccessRequests, refreshToggle])

    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>
    if (!loading && !userAccessRequests) return <p>Tilgangsforespørsler ble ikke funnet</p>

    const handleAccept = async request => {
        let manufacturers = [];
        const userData = await db.doc(`users/${request.userId}`).get();

        if (userData.exists && userData.data() && Array.isArray(userData.data().manufacturers)) {
            manufacturers = userData.data().manufacturers;
        }

        manufacturers.push(request.id);

        await db.doc(`users/${request.userId}`).set({
            manufacturers,
            name: request.name,
            phoneNumber: request.phoneNumber,
        }, { merge: true });
        await db.doc(`userAccessRequests/${request.userId}/requests/${request.id}`).delete();

        setRefreshToggle(!refreshToggle);
    }

    const handleReject = async request => {
        alert("Denne funksjonen er ikke implementert enda");
    }

    const columns = [
        {
            field: 'name',
            name: 'Navn',
        },
        {
            field: 'id',
            name: 'Produsent',
        },
        {
            field: 'phoneNumber',
            name: 'Nummer',
            render: phoneNumber => <EuiLink href={`https://www.1881.no/?query=${phoneNumber}`} target="_blank" >{phoneNumber}</EuiLink>
        },
        {
            field: 'userId',
            name: 'Bruker id',
        },
        {
            name: 'Handlinger',
            actions: [
                {
                    name: "Avslå",
                    description: 'Avslå denne forespørselen',
                    icon: 'cross',
                    color: 'danger',
                    type: 'button',
                    onClick: handleReject,
                    'data-test-subj': 'action-reject',
                },
                {
                    name: "Godta",
                    description: 'Godta denne forespørselen',
                    icon: 'check',
                    color: 'primary',
                    type: 'button',
                    onClick: handleAccept,
                    isPrimary: true,
                    'data-test-subj': 'action-accept',
                },
            ],
        },
    ];

    return (
        <Fragment>
            <EuiBasicTable
                hasActions
                items={loading ? [] : pageItems}
                rowHeader="id"
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={onTableChange}
            />
        </Fragment>
    );
}

export default AccessRequests;
