import React, { useState, useEffect, Fragment } from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiText,
    EuiCallOut,
    EuiSpacer,
    EuiLoadingChart,
    EuiButtonIcon,
    EuiPageHeader,
    EuiPageSection,
    EuiEmptyPrompt,
    EuiTitle,
    EuiLink,
} from '@elastic/eui';
import moment from 'moment';

import useFunction from '../../../helpers/useFunction';
import StatusRow from '../../../components/StatusRow';
import { getAllSystemsOperational } from '../../../helpers/utils';
import useDocument from '../../../helpers/useDocument';

const StatusPage = ({ match, onNotFound }) => {
    const manufacturerUrl = match?.params?.manufacturerUrl;
    const [functionData] = useState({ url: manufacturerUrl });

    const [loading, error, statusLog, refresh] = useFunction("v2-getStatusLogByUrl", functionData);
    const [loadingMan, errorMan, manufacturer] = useDocument("publicManufacturers", manufacturerUrl);
    const latestStatus = statusLog?.statusLogs ? statusLog?.statusLogs[0] : null;
    const allSysyemsOperational = latestStatus ? getAllSystemsOperational(latestStatus) : false;
    const latestStatusTime = latestStatus ? moment(latestStatus.createdAt._seconds * 1000).format("DD.MM.YYYY HH:mm") : null;

    const notFound = error && error === "manufacturer_not_found";

    useEffect(() => {
        if (notFound && onNotFound) onNotFound();
    }, [notFound, onNotFound])

    const now = new Date();
    const oneMonth = 1 * 30 * 24 * 60 * 60 * 1000;

    const getCertificateInfo = (url) => {
        if (loadingMan || errorMan || !Array.isArray(manufacturer?.certificateInfos)) return null;

        let stringUrl = `${url}`;

        if (!stringUrl.startsWith("http")) stringUrl = `https://${url}`;

        return manufacturer.certificateInfos.find(info => info.url === stringUrl);
    }

    const StatusRowExtra = ({ url }) => {
        if (!url) return null;

        const certificateInfo = getCertificateInfo(url);
        const validToDate = Date.parse(certificateInfo?.validTo);
        const diff = validToDate - now;
        const color = diff < 0 ? "danger" : diff < oneMonth ? "warning" : "success";

        return (
            <Fragment>
                {certificateInfo?.validTo && (
                    <EuiText color={color} >
                        Expiration date SSL Certificate: {certificateInfo.validTo}
                    </EuiText>
                )}


                <EuiLink
                    target="_blank"
                    href={`https://www.ssllabs.com/ssltest/analyze.html?d=${url}&hideResults=on`}
                >
                    Run SSL Server Test
                </EuiLink>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <EuiPage>
                <EuiPageBody>
                    <EuiPageSection>
                        <EuiPageHeader
                            paddingSize="l"
                            restrictWidth
                            bottomBorder
                            pageTitle={notFound ? "Status page not found" : statusLog?.manufacturer}
                            rightSideItems={[
                                <EuiButtonIcon
                                    onClick={refresh}
                                    iconType="refresh"
                                    aria-label="Refresh"
                                    disabled={loading}
                                />,
                                <EuiText size="xs" ><p>{latestStatusTime}</p></EuiText>,
                            ]}
                        />
                    </EuiPageSection>

                    {loading && (
                        <EuiPageSection restrictWidth >
                            <div style={{ textAlign: 'center' }} ><EuiLoadingChart size="xl" /></div>
                        </EuiPageSection>
                    )}

                    {error && (
                        <EuiPageSection
                            alignment="center"
                            grow
                        >
                            <EuiEmptyPrompt title={<span>{notFound ? "404" : "Something went wrong"}</span>} />
                        </EuiPageSection>
                    )}

                    {latestStatus && (
                        <Fragment>
                            <EuiPageSection restrictWidth >
                                {allSysyemsOperational
                                    ? <EuiCallOut title="All Systems Operational" color="success" />
                                    : <EuiCallOut title="There seems to be a problem" color="danger" />
                                }
                            </EuiPageSection>

                            <EuiPageSection restrictWidth  >
                                <EuiTitle>
                                    <h2>CalWin</h2>
                                </EuiTitle>
                                <EuiSpacer />
                                <StatusRow
                                    showRule
                                    title="Server"
                                    status={latestStatus.cwserver.status}
                                    extraItems={<StatusRowExtra url={latestStatus?.cwserver?.data?.host} />}
                                />
                                <StatusRow title="Database" status={latestStatus.cwDB.status} />
                            </EuiPageSection>

                            <EuiPageSection restrictWidth >
                                <EuiTitle>
                                    <h2>Octaos</h2>
                                </EuiTitle>
                                <EuiSpacer />

                                {latestStatus.frontend.map(frontend => (
                                    <StatusRow
                                        showRule
                                        key={frontend.data.domain}
                                        title={frontend.data.domain}
                                        status={frontend.status}
                                        extraItems={<StatusRowExtra url={frontend.data.domain} />}
                                    />
                                ))}

                                {latestStatus.sites && latestStatus.sites.length > 0 && (
                                    <Fragment>
                                        {latestStatus.sites.map(site => (
                                            <StatusRow
                                                showRule
                                                key={site.data.name}
                                                title={site.data.name}
                                                subtitle={site.data.url}
                                                status={site.status}
                                                extraItems={<StatusRowExtra url={site.data.url} />}
                                            />
                                        ))}
                                    </Fragment>
                                )}
                            </EuiPageSection>
                        </Fragment>
                    )}
                </EuiPageBody>
            </EuiPage>

        </Fragment >
    );
}

export default StatusPage;
