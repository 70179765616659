import React from 'react';
import {
	EuiPage,
	EuiPageBody,
	EuiPageSection,
	EuiPageHeader,
	EuiPageHeaderSection,
	EuiTitle,
	EuiButton,
	EuiLoadingSpinner,
	EuiPageSidebar,
} from '@elastic/eui';

import Login from './Login';
import Dashboard from './Dashboard';
import SideNav from './SideNav';
import useUser from '../../helpers/useUser';

const Admin = ({ match }) => {
	const { user, loading, data, loadingData, hasFetchedData, signOut } = useUser();

	if (loading) return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }} ><EuiLoadingSpinner size="xl" /></div>

	const isDev = process.env.REACT_APP_FIREBASE_ENV === "dev" || process.env.NODE_ENV === "development";
	const isNonAdminUser = user && !loadingData && (!data || !data.isAdmin) && hasFetchedData;
	const isSignedIn = user && !isNonAdminUser;

	return (
		<EuiPage paddingSize="m" restrictWidth={!isSignedIn ? 400 : false} >
			{isSignedIn && <EuiPageSidebar><SideNav /></EuiPageSidebar>}
			<EuiPageBody component="div">
				<EuiPageHeader bottomBorder >
					<EuiPageHeaderSection>
						<EuiTitle>
							<h2>Admin{isDev ? " (dev)" : ""}</h2>
						</EuiTitle>
					</EuiPageHeaderSection>
					{user && (
						<EuiPageHeaderSection>
							<span style={{ marginRight: 8 }} >{user.email}</span>
							<EuiButton onClick={signOut} >Logg ut</EuiButton>
						</EuiPageHeaderSection>
					)}
				</EuiPageHeader>

				<EuiPageSection color="plain" >
					{!isSignedIn ? <Login /> : <Dashboard match={match} />}
				</EuiPageSection>
			</EuiPageBody>
		</EuiPage>
	);
}

export default Admin;
