import React, { Fragment, useState } from 'react';
import { EuiCallOut, EuiText, EuiButton } from '@elastic/eui';

import PageError from '../../../../components/PageError';
import Spinner from '../../../../components/Spinner';

import useCollection from '../../../../helpers/useCollection';
import { sendAccessRequest } from '../../../../helpers/firebaseActions';
import NameForm from '../../Login/NameForm';
import useFunction from '../../../../helpers/useFunction';

const NoAccessView = ({ user, manufacturerUrl }) => {
    const [functionData] = useState({ url: manufacturerUrl });
    const [loadingExists, errorExists, manufacturerExistsRes] = useFunction("v2-getManufacturerExistsByUrl", functionData);

    const collection = `userAccessRequests/${user.uid}/requests`;
    const [loading, error, accessRequests] = useCollection(collection);
    const [sending, setSending] = useState(false);
    const [needsName, setNeedsName] = useState(!user.displayName);

    const hasAccessRequest = accessRequests && accessRequests.some(({ id }) => id === manufacturerUrl);

    const handleSend = async () => {
        setSending(true);

        await sendAccessRequest(user, manufacturerUrl);

        setSending(false);
    }

    if (manufacturerExistsRes && !manufacturerExistsRes.exists) {
        return (
            <Fragment>
                <EuiText textAlign="center" >
                    <h2>404 Page Not Found</h2>
                    <h3>Manufacturer <code>{manufacturerUrl}</code> does not exist</h3>
                </EuiText>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <EuiCallOut title="You do not have access to view this page" color="warning" iconType="help" />
            {(loading || loadingExists) && <Spinner />}
            {error && <PageError title="Something went wrong while trying to get your access requests" />}
            {errorExists && <PageError title="Something went wrong while trying to get manufacturer" />}

            {!loading && !loadingExists && !error && !errorExists && accessRequests && (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} >
                    {hasAccessRequest ?
                        (
                            <Fragment>
                                <EuiText>Your request has been sent and we will have to confirm it before you get access</EuiText>
                            </Fragment>
                        ) : (
                            <Fragment>
                                {!needsName ?
                                    (
                                        <EuiButton isLoading={sending} disabled={sending} onClick={handleSend} >Request access</EuiButton>

                                    ) : (
                                        <NameForm manufacturerUrl={manufacturerUrl} onSuccess={() => setNeedsName(false)} />
                                    )}
                            </Fragment>
                        )}
                </div>
            )}
        </Fragment>
    )
}

export default NoAccessView;
