import React, { useState } from 'react';
import {
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiButton,
    EuiText,
    EuiSwitch,
    EuiComboBox,
    EuiHorizontalRule,
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiButtonIcon,
} from '@elastic/eui';
import { useHistory } from 'react-router';

import { db } from "../../../../helpers/firebase";
import ManufacturerSiteFrom from './components/ManufacturerSiteFrom';

const ManufacturerForm = ({ manufacturer }) => {
    const history = useHistory();

    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(null)

    const [id, setId] = useState(manufacturer?.id ? manufacturer.id : "");
    const [name, setName] = useState(manufacturer?.name ? manufacturer.name : "");
    const [statusUrl, setStatusUrl] = useState(manufacturer?.statusUrl ? manufacturer.statusUrl : "");
    const [active, setActive] = useState(manufacturer?.active ? manufacturer.active : false);
    const [phoneList, setPhoneList] = useState(manufacturer?.phoneList ? manufacturer.phoneList : []);
    const [emailList, setEmailList] = useState(manufacturer?.emailList ? manufacturer.emailList : []);
    const [sites, setSites] = useState(manufacturer?.sites ? manufacturer.sites : []);
    const [coreApiUrl, setCoreApiUrl] = useState(manufacturer?.coreApiUrl ? manufacturer.coreApiUrl : "");

    const [phoneListOptions, setPhoneListOptions] = useState(manufacturer?.phoneList ? manufacturer.phoneList : []);
    const [emailListOptions, setEmailListOptions] = useState(manufacturer?.emailList ? manufacturer.emailList : []);

    const isEdit = !!manufacturer;
    const isEdited = !isEdit ? true :
        name !== manufacturer.name ||
        statusUrl !== manufacturer.statusUrl ||
        active !== manufacturer.active ||
        coreApiUrl !== manufacturer.coreApiUrl ||
        JSON.stringify(phoneList) !== JSON.stringify(manufacturer.phoneList) ||
        JSON.stringify(emailList) !== JSON.stringify(manufacturer.emailList) ||
        JSON.stringify(sites) !== JSON.stringify(manufacturer.sites);
    const isValid = id.length > 0 && name.length > 0 && statusUrl.length > 0 && isEdited;

    const handleSubmit = async e => {
        e.preventDefault();

        if (isValid) {
            const formatedStatusUrl = statusUrl.split(" ").join("").toLowerCase();

            setSaving(true)
            setError(null)
            setStatusUrl(formatedStatusUrl)

            const data = {
                name,
                statusUrl: formatedStatusUrl,
                active,
                phoneList,
                emailList,
                sites,
                coreApiUrl,
            }

            try {
                if (isEdit) {
                    await db.collection("manufacturers").doc(id).update(data)
                } else {
                    const doc = await db.collection("manufacturers").doc(id).get();

                    if (doc.exists) throw new Error("Det finnes en produsent med denne id'en fra før");

                    await db.collection("manufacturers").doc(id).set(data)
                }

                setSaving(false)

                history.push(`/admin/manufacturers/${id}/edit`);
            } catch (error) {
                console.log(error)

                setError(error)
                setSaving(false)
            }
        }
    }

    const resetError = () => setError(null);

    const handleCreatePhoneListOption = (searchValue, flattenedOptions = []) => {
        const normalizedSearchValue = searchValue.trim().toLowerCase();

        if (!normalizedSearchValue) return;

        const newOption = { label: searchValue };

        if (
            flattenedOptions.findIndex(
                option => option.label.trim().toLowerCase() === normalizedSearchValue
            ) === -1
        ) {
            setPhoneListOptions([...phoneListOptions, newOption]);
        }

        setPhoneList([...phoneList, newOption]);
    }

    const handleCreateEmailListOption = (searchValue, flattenedOptions = []) => {
        const normalizedSearchValue = searchValue.trim().toLowerCase();

        if (!normalizedSearchValue) return;

        const newOption = { label: searchValue };

        if (
            flattenedOptions.findIndex(
                option => option.label.trim().toLowerCase() === normalizedSearchValue
            ) === -1
        ) {
            setEmailListOptions([...emailListOptions, newOption]);
        }

        setEmailList([...emailList, newOption]);
    }

    const handleSiteEdit = (name, site) => {
        const oldIndex = sites.findIndex(s => s.name === name);
        const newSites = sites.map((s, i) => i === oldIndex ? site : s);

        setSites(newSites);
    }

    return (
        <EuiForm component="form" onSubmit={handleSubmit} >
            <EuiFormRow>
                <EuiSwitch
                    name="active"
                    label="Aktiv"
                    checked={active}
                    onChange={e => setActive(e.target.checked)}
                />
            </EuiFormRow>

            <EuiFormRow label="Id" helpText="GUID fra Core API" >
                <EuiFieldText name="id" disabled={isEdit} value={id} onChange={e => setId(e.target.value)} onFocus={resetError} />
            </EuiFormRow>

            <EuiFormRow label="Navn" >
                <EuiFieldText name="name" value={name} onChange={e => setName(e.target.value)} onFocus={resetError} />
            </EuiFormRow>

            <EuiFormRow label="Status url" >
                <EuiFieldText
                    name="statusUrl"
                    prepend={`${window.location.origin}/`}
                    value={statusUrl}
                    onChange={e => setStatusUrl(e.target.value)}
                    onFocus={resetError}
                    onBlur={e => setStatusUrl(e.target.value.split(" ").join("").toLowerCase())}
                />
            </EuiFormRow>

            <EuiHorizontalRule />

            <EuiFormRow label="SMS varsling" >
                <EuiComboBox
                    placeholder="Opprett alternativer"
                    options={phoneListOptions}
                    selectedOptions={phoneList}
                    onChange={setPhoneList}
                    onCreateOption={handleCreatePhoneListOption}
                    isClearable={true}
                    data-test-subj="demoComboBox"
                />
            </EuiFormRow>

            <EuiFormRow label="Email varsling" >
                <EuiComboBox
                    placeholder="Opprett alternativer"
                    options={emailListOptions}
                    selectedOptions={emailList}
                    onChange={setEmailList}
                    onCreateOption={handleCreateEmailListOption}
                    isClearable={true}
                    data-test-subj="demoComboBox2"
                />
            </EuiFormRow>

            <EuiHorizontalRule />

            <EuiText><h4>Andre sider for status</h4></EuiText>

            <EuiSpacer />

            <EuiFlexGroup direction="column" gutterSize="m" >
                {sites.map(site => (
                    <EuiFlexItem key={site.name} >
                        <span>
                            <ManufacturerSiteFrom
                                site={site}
                                onEdit={handleSiteEdit}
                                getIsUnique={name => name === site.name || sites.every(s => s.name !== name)}
                            />
                            <EuiButtonIcon
                                color="danger"
                                iconType="cross"
                                aria-label="Remove"
                                onClick={() => setSites(sites.filter(s => s.name !== site.name))}
                            />
                        </span>
                    </EuiFlexItem>
                ))}
            </EuiFlexGroup>

            <EuiSpacer />

            <ManufacturerSiteFrom onAdd={site => setSites([...sites, site])} getIsUnique={name => sites.every(s => s.name !== name)} />

            {/* <EuiFormRow label="Andre sider for status" helpText="F.eks https://www.octaos.com" >
                <EuiComboBox
                    isDisabled
                    placeholder="Opprett alternativer"
                    isClearable={true}
                    data-test-subj="demoComboBox3"
                />
            </EuiFormRow> */}

            <EuiHorizontalRule />

            <EuiFormRow label="Core Api Url" >
                <EuiFieldText name="coreApiUrl" value={coreApiUrl} onChange={e => setCoreApiUrl(e.target.value)} onFocus={resetError} />
            </EuiFormRow>

            <EuiHorizontalRule />

            {error && (
                <EuiText color="danger" >
                    {error.stack && error.message ? <p>{error.message}</p> : <pre>{JSON.stringify(error, null, 4)}</pre>}
                </EuiText>
            )}

            <EuiButton type="submit" fill disabled={!isValid || saving} isLoading={saving} >
                Lagre
            </EuiButton>
        </EuiForm>
    );
}

export default ManufacturerForm;
