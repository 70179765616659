import React, { useState } from 'react';
import { EuiSideNav, EuiIcon } from '@elastic/eui';

import useNav from '../../helpers/useNav';
import useCollection from '../../helpers/useCollection';

const SideNav = () => {
    const [nav, currentPath] = useNav();
    const [loading, error, manufacturers] = useCollection("manufacturers");

    const [isSideNavOpenOnMobile, setisSideNavOpenOnMobile] = useState(false);

    const toggleOpenOnMobile = () => setisSideNavOpenOnMobile(!isSideNavOpenOnMobile);

    const createItem = (name, path, data = {}) => ({
        ...data,
        id: name,
        name,
        isSelected: currentPath === path,
        onClick: path ? e => {
            nav(e, path);

            if (!data.items) toggleOpenOnMobile();
        } : null,
    })

    const createPath = path => `/admin${path}`;

    const manufacturerItems = loading || error ?
        [createItem(loading ? "Laster..." : JSON.stringify(error))]
        :
        manufacturers.map(man => createItem(man.name, createPath(`/manufacturers/${man.id}`), {
            items: [
                createItem("Rediger", createPath(`/manufacturers/${man.id}/edit`)),
                createItem("Log", createPath(`/manufacturers/${man.id}/logs`)),
            ]
        }));

    const sideNav = [
        createItem("Octaos Status Admin", createPath(""), {
            items: [
                createItem("Produsenter", createPath("/manufacturers"), {
                    items: [
                        ...manufacturerItems,
                        createItem("Legg til", createPath("/manufacturers/add"), { icon: <EuiIcon type="listAdd" /> })
                    ],
                }),
                createItem("Octaos Applikasjoner", createPath("/octaos-apps"), {
                    items: [
                        createItem("Log", createPath("/octaos-apps/logs")),
                    ]
                }),
                createItem("Kjør status sjekk", createPath("/run-status-check")),
                createItem("Mail log", createPath("/mail-logs")),
                createItem("SMS log", createPath("/sms-logs")),
                createItem("Sertifikat", createPath("/certificates")),
                createItem("Brukere", createPath("/users")),
            ]
        }),
    ];

    return (
        <EuiSideNav
            mobileTitle="Naviger Octaos Status Admin"
            toggleOpenOnMobile={() => toggleOpenOnMobile()}
            isOpenOnMobile={isSideNavOpenOnMobile}
            items={sideNav}
        />
    );
}

export default SideNav;
