import React, { Fragment } from 'react';
import { EuiTitle, EuiSpacer } from '@elastic/eui';

import UsersView from './UsersView';
import AccessRequests from './AccessRequests';

const Users = ({ match }) => {
    return (
        <Fragment>
            <EuiTitle size="s" ><h3>Tilgangsforespørsler</h3></EuiTitle>
            <AccessRequests />

            <EuiSpacer size="xl" />

            <EuiTitle size="s" ><h3>Brukere</h3></EuiTitle>
            <UsersView />
        </Fragment>
    );
}

export default Users;
