import { useState, useEffect } from 'react';

import { functions } from "../helpers/firebase";

const useFunction = (functionName, functionData = null) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [toggleRefresh, setToggleRefresh] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            setData(null);

            const callable = functions.httpsCallable(functionName);
            const res = await callable(functionData).catch(setError);

            setIsLoading(false);

            if (res.data.error) {
                setError(res.data.error);
            } else {
                setData(res.data);
            }
        }

        fetchData()
    }, [functionName, functionData, toggleRefresh])

    const refresh = () => setToggleRefresh(!toggleRefresh);

    return [isLoading, error, data, refresh];
}

export default useFunction;
