import { useState, useEffect } from 'react';

import { auth, db } from './firebase'

const useUser = () => {
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null)
    const [data, setData] = useState(null)
    const [loadingData, setLoadingData] = useState(true)
    const [hasFetchedData, setHasFetchedData] = useState(false)

    useEffect(() => {
        let unsubscribe = auth.onAuthStateChanged(user => {
            if (unsubscribe) {
                setLoading(false)
                setUser(user)
            }
        })

        return () => {
            unsubscribe();
            unsubscribe = null;
        }
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            setLoadingData(true);

            const doc = await db.collection("users").doc(user.uid).get();

            setData(doc.data());
            setLoadingData(false);
            setHasFetchedData(true);
        }

        if (user) {
            fetchData();
        } else {
            setData(null);
            setHasFetchedData(false);
        }
    }, [user])

    const signOut = (_) => auth.signOut();
    const getCurrentUser = () => auth.currentUser;

    return { user, loading, signOut, getCurrentUser, data, loadingData, hasFetchedData };
}

export default useUser;
