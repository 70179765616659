import React, { Fragment, useState } from 'react';
import { EuiButton, EuiSpacer } from '@elastic/eui';

import { functions } from "../../../../helpers/firebase";

const RunStatusCheck = () => {
    const [runningCheck, setRunningCheck] = useState(false);
    const [runningCheckOctaosApps, setRunningCheckOctaosApps] = useState(false);
    const [runningCheckCertificate, setRunningCheckCertificate] = useState(false);

    const handleStatusCheck = async () => {
        setRunningCheck(true);

        const callable = functions.httpsCallable("v2-runStatusCheck");

        try {
            await callable();
        } catch (error) {
            console.error(error);
        }

        setRunningCheck(false);
    }

    const handleStatusCheckOctaosApps = async () => {
        setRunningCheckOctaosApps(true);

        const callable = functions.httpsCallable("v2-runStatusCheckOctaosApps");

        try {
            await callable();
        } catch (error) {
            console.error(error);
        }

        setRunningCheckOctaosApps(false);
    }

    const handleCheckCertificate = async () => {
        setRunningCheckCertificate(true);

        const callable = functions.httpsCallable("v2-runCertificateCheck");

        try {
            await callable();
        } catch (error) {
            console.error(error);
        }

        setRunningCheckCertificate(false);
    }

    return (
        <Fragment>
            <EuiButton
                disabled={runningCheck}
                isLoading={runningCheck}
                onClick={handleStatusCheck}
            >
                Status sjekk for produsenter
            </EuiButton>

            <EuiSpacer />

            <EuiButton
                disabled={runningCheckOctaosApps}
                isLoading={runningCheckOctaosApps}
                onClick={handleStatusCheckOctaosApps}
            >
                Status sjekk for Octaos Apps
            </EuiButton>

            <EuiSpacer />

            <EuiButton
                disabled={runningCheckCertificate}
                isLoading={runningCheckCertificate}
                onClick={handleCheckCertificate}
            >
                Sjekk sertifikater
            </EuiButton>
        </Fragment>
    );
}

export default RunStatusCheck;
