import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Admin from './pages/Admin';
import Home from './pages/Home';
import Manufacturer from './pages/Manufacturer';

const App = () => {
	return (
		<Router>
			<div className="App">
				<Switch>
					<Route path="/admin" component={Admin} />

					<Route path="/:manufacturerUrl" component={Manufacturer} />

					<Route path="/" component={Home} />
				</Switch>
			</div>
		</Router>
	);
}

export default App;
