import React, { Fragment } from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiPageSection,
    EuiPageHeader,
    EuiPageHeaderSection,
    EuiTitle,
    EuiText,
    EuiCallOut,
    EuiSpacer,
    EuiLoadingChart,
    EuiHorizontalRule,
} from '@elastic/eui';
import moment from 'moment';

import useCollection from '../../helpers/useCollection';
import StatusRow from '../../components/StatusRow';
import { getAllSystemsOperational } from '../../helpers/utils';

const Home = () => {
    const [loadingCoreApi, errorCoreApi, coreApiStatusLogs] = useCollection("coreApiStatusLogs", "createdAt");
    const [loadingOctaosApps, errorOctaosApps, octaosAppsStatusLogs] = useCollection("octaosAppsStatusLogs", "createdAt");

    const latestStatusCoreApi = coreApiStatusLogs ? coreApiStatusLogs[0] : null;
    const latestStatusOctaosApps = octaosAppsStatusLogs ? octaosAppsStatusLogs[0] : null;
    const latestStatusOctaosAppsArray = latestStatusOctaosApps
        ? Object.keys(latestStatusOctaosApps).map(key => latestStatusOctaosApps[key]).filter(row => row.status)
        : [];

    const statusTimeCoreApi = latestStatusCoreApi ? moment(latestStatusCoreApi.createdAt.seconds * 1000) : null;
    const statusTimeOctaosApps = latestStatusOctaosApps ? moment(latestStatusOctaosApps.createdAt.seconds * 1000) : null;
    const latestStatusTime = statusTimeCoreApi > statusTimeOctaosApps ? statusTimeCoreApi : statusTimeOctaosApps;

    const allSysyemsOperational = latestStatusCoreApi && latestStatusOctaosApps
        ? latestStatusCoreApi.status === 200 && getAllSystemsOperational(latestStatusOctaosApps)
        : false;

    const loading = loadingCoreApi || loadingOctaosApps;

    return (
        <Fragment>
            <EuiPage restrictWidth={600} paddingSize="l" >
                <EuiPageBody component="div">
                    <EuiPageSection color="plain" >
                        <EuiPageHeader responsive={false} bottomBorder >
                            <EuiPageHeaderSection>
                                <EuiTitle>
                                    <h2>Octaos Status</h2>
                                </EuiTitle>
                            </EuiPageHeaderSection>

                            {latestStatusTime && (
                                <EuiPageHeaderSection>
                                    <EuiText size="xs" ><p>{latestStatusTime.format("DD.MM.YYYY HH:mm")}</p></EuiText>
                                </EuiPageHeaderSection>
                            )}
                        </EuiPageHeader>

                        {loading && <div style={{ textAlign: 'center' }} ><EuiLoadingChart size="xl" /></div>}

                        {(errorCoreApi || errorOctaosApps) && (
                            <EuiText>
                                <h3>Something went wrong</h3>
                            </EuiText>
                        )}

                        {(latestStatusCoreApi || latestStatusOctaosApps) && (
                            <Fragment>
                                {allSysyemsOperational
                                    ? <EuiCallOut title="All Systems Operational" color="success" />
                                    : <EuiCallOut title="There seems to be a problem" color="danger" />
                                }

                                <EuiSpacer />

                                {latestStatusCoreApi && (
                                    <StatusRow title="Octaos API" status={latestStatusCoreApi.status} />
                                )}

                                {latestStatusOctaosAppsArray.length > 0 && (
                                    <Fragment>
                                        <EuiHorizontalRule />

                                        {latestStatusOctaosAppsArray.map(octaosAppStatus => (
                                            <StatusRow
                                                key={octaosAppStatus.data.id}
                                                title={octaosAppStatus.data.name}
                                                subtitle={octaosAppStatus.data.url}
                                                status={octaosAppStatus.status}
                                            />
                                        ))}
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </EuiPageSection>
                </EuiPageBody>
            </EuiPage>
        </Fragment>
    );
}

export default Home;
