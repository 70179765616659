import React, { Fragment, useState } from 'react';
import { EuiBasicTable, EuiHealth, EuiLink } from '@elastic/eui';
import moment from 'moment';

import useFunction from '../../../../helpers/useFunction';
import TextModal from './../../../../components/TextModal';
import usePagination from '../../../../helpers/usePagination';

const sortCertificateInfos = (certificateInfos, field, direction) => {
    if (!Array.isArray(certificateInfos)) return certificateInfos;

    const array = [...certificateInfos].reduce((acc, { certificateInfos }) => {
        if (!Array.isArray(certificateInfos)) return acc;

        return [...acc, ...certificateInfos];
    }, [])

    const res = array.filter(row => !row.error);

    res.sort((a, b) => {
        const timeA = a[field].replace(" GMT", "");
        const timeB = b[field].replace(" GMT", "");
        const momentA = moment(timeA, "MMM D hh:mm:ss YYYY");
        const momentB = moment(timeB, "MMM D hh:mm:ss YYYY");

        if (direction === "desc") return momentB - momentA;

        return momentA - momentB;
    })

    return res;
}

const CertificateInfo = () => {
    const [loading, error, certificateInfos] = useFunction("v2-getCertificateInfos");
    const [currentText, setCurrentText] = useState(null);
    const [sortField, setSortField] = useState('validTo');
    const [sortDirection, setSortDirection] = useState('asc');
    const sortedCertificateInfos = sortCertificateInfos(certificateInfos, sortField, sortDirection);
    const { pagination, onTableChange: paginationOnTableChange, pageItems } = usePagination(sortedCertificateInfos);

    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>
    if (!loading && !certificateInfos) return <p>Ingen info</p>

    const now = new Date();
    const oneMonth = 1 * 30 * 24 * 60 * 60 * 1000;

    const columns = [
        {
            field: 'url',
            name: 'Url',
        },
        {
            field: 'validFrom',
            name: 'Gyldig fra',
            sortable: true,
        },
        {
            field: 'validTo',
            name: 'Gyldig til',
            sortable: true,
            render: validTo => {
                const validToDate = Date.parse(validTo);
                const diff = validToDate - now;
                const color = diff < 0 ? "danger" : diff < oneMonth ? "warning" : "success";

                return <EuiHealth color={color} >{validTo}</EuiHealth>;
            },
        },
        {
            field: 'issuer',
            name: 'Utsteder',
            render: issuer => <EuiLink onClick={() => setCurrentText(JSON.stringify(issuer, null, 4))} >{issuer?.O || "(ukjent)"}</EuiLink>,
        },
    ];

    const onTableChange = ({ sort = {}, page = {} }) => {
        const { field: sortField, direction: sortDirection } = sort;

        setSortField(sortField);
        setSortDirection(sortDirection);

        paginationOnTableChange({ page });
    };

    const sorting = {
        sort: {
            field: sortField,
            direction: sortDirection,
        },
    };

    return (
        <Fragment>
            <EuiBasicTable
                items={loading ? [] : pageItems}
                rowHeader="id"
                columns={columns}
                loading={loading}
                sorting={sorting}
                pagination={pagination}
                onChange={onTableChange}
            />

            {currentText && <TextModal closeModal={() => setCurrentText(null)} text={currentText} />}
        </Fragment>
    );
}

export default CertificateInfo;
