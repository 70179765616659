import React, { Fragment, useState } from 'react';
import { EuiBasicTable, EuiHealth, EuiFlexGroup, EuiFlexItem, EuiLink } from '@elastic/eui';
import moment from 'moment';

import useCollection from '../../../../helpers/useCollection';
import StatusModal from './StatusModal';
import usePagination from '../../../../helpers/usePagination';

const ManufacturerLogs = ({ match }) => {
    const [loading, error, statusLogs] = useCollection(`manufacturers/${match.params.manufacturerId}/statusLogs`, "createdAt");
    const [currentStatusObject, setCurrentStatusObject] = useState(null);
    const { pagination, onTableChange, pageItems } = usePagination(statusLogs);

    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>
    if (!loading && !statusLogs) return <p>Logger ble ikke funnet</p>

    const StatusRow = ({ statusObject, title }) => {
        const status = statusObject.status;
        const isUp = status === 200;
        const color = isUp ? 'success' : 'danger';

        const Link = () => <EuiLink onClick={() => setCurrentStatusObject(statusObject)} >{status}</EuiLink>

        if (title) return <EuiHealth color={color}><span>{title}: <Link /></span></EuiHealth>;

        return <EuiHealth color={color}><Link /></EuiHealth>;
    }

    const columns = [
        {
            field: 'id',
            name: 'Id',
        },
        {
            field: 'createdAt',
            name: 'Opprettet',
            render: createdAt => moment(createdAt.seconds * 1000).format("DD.MM.YYYY HH:mm"),
        },
        {
            field: 'cwserver',
            name: 'CalWin Server',
            render: cwserver => <StatusRow statusObject={cwserver} />,
        },
        {
            field: 'coreApi',
            name: 'Core API',
            render: coreApi => <StatusRow statusObject={coreApi} />,
        },
        {
            field: 'cwDB',
            name: 'CalWin database',
            render: cwDB => <StatusRow statusObject={cwDB} />,
        },
        {
            field: 'frontend',
            name: 'Frontend',
            render: frontend => (
                <EuiFlexGroup direction="column" gutterSize="none" >
                    {frontend.map(statusObject => (
                        <EuiFlexItem key={statusObject.data.domain} grow={false} >
                            <StatusRow statusObject={statusObject} title={statusObject.data.domain} />
                        </EuiFlexItem>
                    ))}
                </EuiFlexGroup>
            ),
        },
        {
            field: 'sites',
            name: 'Sider',
            render: sites => (
                <Fragment>
                    {sites && (
                        <EuiFlexGroup direction="column" gutterSize="none" >
                            {sites.map(statusObject => (
                                <EuiFlexItem key={statusObject.data.name} grow={false} >
                                    <StatusRow statusObject={statusObject} title={`${statusObject.data.name}`} />
                                </EuiFlexItem>
                            ))}
                        </EuiFlexGroup>
                    )}
                </Fragment>
            ),
        },
    ];

    return (
        <Fragment>
            <EuiBasicTable
                items={loading ? [] : pageItems}
                rowHeader="id"
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={onTableChange}
            />

            {currentStatusObject && <StatusModal closeModal={() => setCurrentStatusObject(null)} statusObject={currentStatusObject} />}
        </Fragment>
    );
}

export default ManufacturerLogs;
