
import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import {
    EuiButton,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiText,
    EuiHorizontalRule,
} from '@elastic/eui';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { auth, firebase } from "../../../helpers/firebase"
import useUser from '../../../helpers/useUser';
import { sendAccessRequest } from '../../../helpers/firebaseActions';
import NameForm from './NameForm';

const CustomPhoneInput = React.forwardRef((props, ref) => <EuiFieldText inputRef={ref} {...props} />)

const Login = ({ match }) => {
    const { user } = useUser();
    const [phoneNumber, setPhoneNumber] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [needsName, setNeedsName] = useState(false);

    const manufacturerUrl = match.params.manufacturerUrl;

    useEffect(() => {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
                'size': 'invisible',
            });
        }

        return () => {
            if (window.recaptchaVerifier) {
                console.log("clear recaptchaVerifier");
                window.recaptchaVerifier.clear();
                window.recaptchaVerifier = null;
            }
        }
    }, [])

    const resetError = () => setError(null)

    const handleSubmitError = async () => {
        const widgetId = await window.recaptchaVerifier.render();

        return window.grecaptcha.reset(widgetId);
    }

    const handleSubmit = async e => {
        if (e) e.preventDefault();

        if (!loading) {
            setLoading(true)
            setError(null)

            try {
                const appVerifier = window.recaptchaVerifier;
                const confirmationResult = await auth.signInWithPhoneNumber(phoneNumber, appVerifier);
                const verificationCode = window.prompt('Please enter the verification code that was sent to your mobile device.');
                const userCredential = await confirmationResult.confirm(verificationCode);

                if (userCredential?.user?.uid) {
                    if (userCredential.user.displayName)
                        await sendAccessRequest(userCredential.user, manufacturerUrl);
                    else
                        setNeedsName(true);
                }
            } catch (e) {
                console.log(e)
                if (e.code === "auth/invalid-verification-code")
                    setError("Invalid code, please try again")
                else if (e.code === "auth/invalid-phone-number")
                    setError("Invalid phone number")
                else
                    setError("Something went wrong")

                handleSubmitError()
            }

            setLoading(false)
        }
    }

    const handleNameSuccess = async () => {
        setLoading(true);
        await sendAccessRequest(user, manufacturerUrl);
        setLoading(false);
        setNeedsName(false);
    }

    if (user && !loading && !needsName) return <Redirect to={`/${manufacturerUrl}/speed-test`} />

    const helpText = `If you don't already have access to ${manufacturerUrl}, you will send an access request after your phone number has been verified`

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} >
            <div style={{ maxWidth: 500 }} >
                <EuiText>
                    <h1>Log in to {manufacturerUrl}</h1>
                    <h5>To get access to {manufacturerUrl}, you have to verify your phone number first.</h5>
                </EuiText>

                <EuiHorizontalRule />

                {needsName ?
                    (
                        <NameForm
                            user={user}
                            manufacturerUrl={manufacturerUrl}
                            onSuccess={handleNameSuccess}
                        />
                    ) : (
                        <EuiForm component="form" onSubmit={handleSubmit} isInvalid={error !== null} error={error} >
                            <EuiFormRow label="Phone number" helpText={helpText} isInvalid={error !== null} >
                                <PhoneInput
                                    international
                                    defaultCountry="NO"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={setPhoneNumber}
                                    onFocus={resetError}
                                    inputComponent={CustomPhoneInput}
                                    autoFocus
                                />
                            </EuiFormRow>

                            <EuiSpacer />

                            <EuiButton
                                id="sign-in-button"
                                onClick={() => handleSubmit()}
                                fill
                                disabled={loading}
                                isLoading={loading}
                            >
                                Send code
                            </EuiButton>
                        </EuiForm>
                    )}

            </div>
        </div >
    );

}

export default Login;