import React from 'react';

import {
    EuiLoadingSpinner,
    EuiText,
} from '@elastic/eui';

const Spinner = ({ text }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }} >
            <EuiLoadingSpinner size="xl" />
            {text && <EuiText style={{ marginTop: 16 }} >{text}</EuiText>}
        </div>
    );
};

export default Spinner;