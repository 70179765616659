import React, { Fragment } from 'react';
import { EuiText, EuiSpacer, EuiLink, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiIcon } from '@elastic/eui';
import { useHistory } from 'react-router';

import Manufacturer from '../../../Manufacturer/StatusPage';
import useDocument from '../../../../helpers/useDocument';

const ViewManufacturer = ({ match }) => {
    const history = useHistory();
    const [loading, error, manufacturer] = useDocument("manufacturers", match.params.manufacturerId);

    if (loading) return <p>Laster...</p>
    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>
    if (!manufacturer) return <p>Produsent ble ikke funnet</p>

    const MenuItem = ({ label, path, icon }) => (
        <EuiKeyPadMenuItem label={label} onClick={() => history.push(`${match.url}${path}`)} >
            <EuiIcon type={icon} size="l" />
        </EuiKeyPadMenuItem>
    )

    return (
        <Fragment>
            <EuiText>
                <h3>
                    {manufacturer.name}
                    {' '}
                    <EuiLink onClick={() => history.push(`/${manufacturer.statusUrl}`)} >/{manufacturer.statusUrl}</EuiLink>
                </h3>
            </EuiText>

            <EuiSpacer />

            <EuiKeyPadMenu>
                <MenuItem label="Rediger" path="/edit" icon="pencil" />
                <MenuItem label="Log" path="/logs" icon="email" />
            </EuiKeyPadMenu>

            <EuiSpacer />

            <Manufacturer match={{ params: { manufacturerUrl: manufacturer.statusUrl } }} />
        </Fragment>
    );
}

export default ViewManufacturer;
