import React, { Fragment } from 'react';
import { EuiText, EuiSpacer, EuiListGroup, EuiListGroupItem, EuiButton } from '@elastic/eui';
import { Route } from "react-router-dom";
import { useLocation, useHistory } from 'react-router';

import EditManufacturer from '../Manufacturer/EditManufacturer';
import ManufacturerForm from './ManufacturerForm';
import useCollection from '../../../../helpers/useCollection';
import ManufacturerLogs from '../Manufacturer/ManufacturerLogs';
import ViewManufacturer from '../Manufacturer/ViewManufacturer';

const AddManufacturer = ({ ...props }) => {
    return (
        <Fragment>
            <EuiText>
                <h3>Legg til produsent</h3>
            </EuiText>

            <EuiSpacer />

            <ManufacturerForm {...props} />
        </Fragment>
    )
}

const ManufacturersView = ({ match }) => {
    const history = useHistory();
    const [loading, error, manufacturers] = useCollection("manufacturers");

    if (loading) return <EuiText><p>Laster...</p></EuiText>
    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>

    return (
        <Fragment>
            <EuiText><h3>Produsenter</h3></EuiText>

            <EuiSpacer />
            
            <EuiButton iconType="listAdd" onClick={() => history.push(`${match.path}/add`)} >Legg til</EuiButton>

            <EuiSpacer />

            <EuiListGroup>
                {manufacturers.map(man => (
                    <EuiListGroupItem key={man.id} label={man.name} onClick={() => history.push(`${match.path}/${man.id}`)} />
                ))}
            </EuiListGroup>
        </Fragment>
    )
}

const Manufacturers = ({ match }) => {
    const location = useLocation();
    const isPath = match.path === location.pathname;

    return (
        <Fragment>
            {isPath && <ManufacturersView match={match} />}

            <Route exact path={`${match.path}/add`} component={AddManufacturer} />

            <Route exact path={`${match.path}/:manufacturerId`} component={ViewManufacturer} />
            <Route exact path={`${match.path}/:manufacturerId/edit`} component={EditManufacturer} />
            <Route exact path={`${match.path}/:manufacturerId/logs`} component={ManufacturerLogs} />
        </Fragment>
    );
}

export default Manufacturers;
