import React, { Fragment, useState } from 'react';
import { EuiBasicTable, EuiHealth, EuiLink } from '@elastic/eui';
import moment from 'moment';

import useCollection from '../../../../helpers/useCollection';
import TextModal from './../../../../components/TextModal';
import usePagination from '../../../../helpers/usePagination';

const MailLogs = () => {
    const [loading, error, statusLogs] = useCollection("mailLogs", "createdAt");
    const [currentText, setCurrentText] = useState(null);
    const { pagination, onTableChange, pageItems } = usePagination(statusLogs);

    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>
    if (!loading && !statusLogs) return <p>Logger ble ikke funnet</p>

    const StatusRow = ({ log }) => {
        let status = log.status;

        if (Array.isArray(log.sendgridData)) {
            const latestRow = log.sendgridData.reduce((acc, row) => {
                if (!acc) return row;
                if (row.timestamp > acc.timestamp) return row;
                return acc;
            }, null);

            if (latestRow) status = latestRow.event;
        }

        let color = "danger";
        if (status === "Sent to sendgrid") color = "warning";
        if (status === "delivered") color = "success";

        return <EuiHealth color={color}>{status}</EuiHealth>;
    }

    const columns = [
        {
            field: 'id',
            name: 'Id',
        },
        {
            field: 'createdAt',
            name: 'Opprettet',
            render: createdAt => moment(createdAt.seconds * 1000).format("DD.MM.YYYY HH:mm"),
        },
        {
            field: 'msg',
            name: 'Melding',
            truncateText: true,
            render: msg => <EuiLink onClick={() => setCurrentText(msg)} >klikk for å se</EuiLink>,
        },
        {
            field: 'error',
            name: 'Feilmelding',
            render: error => error ? <EuiLink onClick={() => setCurrentText(error)} >klikk for å se</EuiLink> : null,
        },
        {
            field: 'sendgridData',
            name: 'SendGrid data',
            render: data => data ? <EuiLink onClick={() => setCurrentText(JSON.stringify(data, null, 4))} >klikk for å se</EuiLink> : null,
        },
        {
            field: 'to',
            name: 'Til',
        },
        {
            field: 'status',
            name: 'Status',
            render: (_, log) => <StatusRow log={log} />,
        },
    ];

    return (
        <Fragment>
            <EuiBasicTable
                items={loading ? [] : pageItems}
                rowHeader="id"
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={onTableChange}
            />

            {currentText && <TextModal closeModal={() => setCurrentText(null)} text={currentText} />}
        </Fragment>
    );
}

export default MailLogs;
