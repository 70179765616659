import React, { Fragment, useState } from 'react';
import { EuiBasicTable, EuiHealth, EuiLink } from '@elastic/eui';
import moment from 'moment';

import useCollection from '../../../../helpers/useCollection';
import StatusModal from './../Manufacturer/StatusModal';
import usePagination from '../../../../helpers/usePagination';

const OctaosAppsLogs = () => {
    const [loading, error, statusLogs] = useCollection("octaosAppsStatusLogs", "createdAt");
    const [currentStatusObject, setCurrentStatusObject] = useState(null);
    const { pagination, onTableChange, pageItems } = usePagination(statusLogs);

    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>
    if (!loading && !statusLogs) return <p>Logger ble ikke funnet</p>

    const StatusRow = ({ statusObject, title }) => {
        const status = statusObject.status;
        const isUp = status === 200;
        const color = isUp ? 'success' : 'danger';

        const Link = () => <EuiLink onClick={() => setCurrentStatusObject(statusObject)} >{status}</EuiLink>

        if (title) return <EuiHealth color={color}><span>{title}: <Link /></span></EuiHealth>;

        return <EuiHealth color={color}><Link /></EuiHealth>;
    }

    const columns = [
        {
            field: 'id',
            name: 'Id',
        },
        {
            field: 'createdAt',
            name: 'Opprettet',
            render: createdAt => moment(createdAt.seconds * 1000).format("DD.MM.YYYY HH:mm"),
        },
    ];

    if (statusLogs && statusLogs.length > 0) {
        const log = statusLogs[0];

        Object.keys(log).forEach(key => {
            const value = log[key];

            if (value.status) {
                columns.push({
                    field: key,
                    name: value.data.name,
                    render: statusObject => statusObject && <StatusRow statusObject={statusObject} />
                })
            }
        })
    }

    return (
        <Fragment>
            <EuiBasicTable
                items={loading ? [] : pageItems}
                rowHeader="id"
                columns={columns}
                loading={loading}
                pagination={pagination}
                onChange={onTableChange}
            />

            {currentStatusObject && <StatusModal closeModal={() => setCurrentStatusObject(null)} statusObject={currentStatusObject} />}
        </Fragment>
    );
}

export default OctaosAppsLogs;
