import React, { Fragment, useState, useEffect } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiSpacer,
    EuiButtonIcon,
} from '@elastic/eui';

import ManufacturerSiteFrom from '../Manufacturers/components/ManufacturerSiteFrom';
import { db } from "../../../../helpers/firebase";

const OctaosAppsForm = ({ apps }) => {
    const [octaosApps, setOctaosApps] = useState(apps ? apps : []);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (JSON.stringify(octaosApps) !== JSON.stringify(apps))
            setOctaosApps(apps)
    }, [apps, octaosApps])

    const handleAppEdit = async (id, newData) => {
        setSaving(true);

        await db.collection("octaosApps").doc(id).update(newData);

        setSaving(false);
    }

    const handleAppAdd = async (data) => {
        setSaving(true);

        await db.collection("octaosApps").doc().set(data);

        setSaving(false);
    }

    const handleAppRemove = async (id) => {
        setSaving(true);

        await db.collection("octaosApps").doc(id).delete();

        setSaving(false);
    }

    return (
        <Fragment>
            <EuiFlexGroup direction="column" gutterSize="m" >
                {octaosApps.map(app => (
                    <EuiFlexItem key={app.name} >
                        <span>
                            <ManufacturerSiteFrom
                                site={app}
                                onEdit={(_, newData) => handleAppEdit(app.id, newData)}
                                getIsUnique={name => name === app.name || octaosApps.every(s => s.name !== name)}
                                saving={saving}
                            />
                            <EuiButtonIcon
                                color="danger"
                                iconType="cross"
                                aria-label="Remove"
                                onClick={() => handleAppRemove(app.id)}
                                disabled={saving}
                            />
                        </span>
                    </EuiFlexItem>
                ))}
            </EuiFlexGroup>

            <EuiSpacer />

            <ManufacturerSiteFrom
                onAdd={handleAppAdd}
                getIsUnique={name => octaosApps.every(s => s.name !== name)}
                saving={saving}
            />
        </Fragment>
    );
}

export default OctaosAppsForm;
