import React, { useState } from 'react';
import {
    EuiAvatar,
    EuiFlexGroup,
    EuiFlexItem,
    EuiHeaderSectionItemButton,
    EuiLink,
    EuiPopover,
    EuiSpacer,
    EuiText,
} from '@elastic/eui';
import { htmlIdGenerator } from '@elastic/eui/lib/services';

import useUser from '../../helpers/useUser';

const HeaderUserMenu = () => {
    const id = htmlIdGenerator()();
    const [isOpen, setIsOpen] = useState(false);
    const { user, signOut, data } = useUser();

    const name = user?.displayName || user?.email || "";
    const isAdmin = data?.isAdmin;

    const onMenuButtonClick = () => setIsOpen(!isOpen);
    const closeMenu = () => setIsOpen(false);

    const button = (
        <EuiHeaderSectionItemButton
            aria-controls={id}
            aria-expanded={isOpen}
            aria-haspopup="true"
            aria-label="Account menu"
            onClick={onMenuButtonClick}>
            <EuiAvatar name={name} size="s" />
        </EuiHeaderSectionItemButton>
    );

    return (
        <EuiPopover
            id={id}
            ownFocus
            repositionOnScroll
            button={button}
            isOpen={isOpen}
            anchorPosition="downRight"
            closePopover={closeMenu}
            panelPaddingSize="none">
            <div style={{ width: 320 }}>
                <EuiFlexGroup
                    gutterSize="m"
                    className="euiHeaderProfile"
                    responsive={false}>
                    <EuiFlexItem grow={false}>
                        <EuiAvatar name={name} size="xl" />
                    </EuiFlexItem>

                    <EuiFlexItem>
                        <EuiText>
                            <p>{name}{isAdmin ? " (admin)" : ""}</p>
                        </EuiText>

                        <EuiSpacer size="m" />

                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiFlexGroup justifyContent="spaceBetween">
                                    <EuiFlexItem grow={false}>
                                        {/* <EuiLink>Edit profile</EuiLink> */}
                                    </EuiFlexItem>

                                    <EuiFlexItem grow={false}>
                                        <EuiLink onClick={signOut} >Log out</EuiLink>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </div>
        </EuiPopover>
    );
};

export default HeaderUserMenu;