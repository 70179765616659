
import React, { useState } from 'react';
import {
    EuiButton,
    EuiFieldPassword,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
} from '@elastic/eui';

import { auth } from "../../helpers/firebase"

const Login = () => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const login = (e) => {
        e.preventDefault();

        if (!loading) {
            setLoading(true)
            setError(null)
    
            auth.signInWithEmailAndPassword(username, password)
                .then(console.log)
                .catch(e => {
                    console.log(e.code)
                    if (e.code === "auth/user-not-found" || e.code === "auth/wrong-password" || e.code === "auth/invalid-email")
                        setError("Feil brukernavn eller passord")
                    else
                        setError("Noe gikk galt")
    
                    setLoading(false)
                })
        }
    }

    const resetError = () => setError(null)

    return (
        <EuiForm component="form" onSubmit={login} isInvalid={error !== null} error={error} >
            <EuiFormRow label="Brukernavn" isInvalid={error !== null} >
                <EuiFieldText
                    name="username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiFormRow label="Passord" isInvalid={error !== null} >
                <EuiFieldPassword
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiSpacer />

            <EuiButton
                type="submit"
                fill
                disabled={loading}
                isLoading={loading}
            >
                Logg inn
            </EuiButton>
        </EuiForm>
    );

}

export default Login;