import React, { Fragment } from 'react';
import { Route } from "react-router-dom";
import { useLocation } from 'react-router';

import Manufacturers from './Manufacturers';
import OctaosApps from './OctaosApps';
import RunStatusCheck from './RunStatusCheck';
import OctaosAppsLogs from './OctaosApps/OctaosAppsLogs';
import DashboardView from './DashboardView';
import MailLogs from './MailLogs';
import SmsLogs from './SmsLogs';
import CertificateInfo from './CertificateInfo';
import Users from './Users';

const Dashboard = ({ match }) => {
    const location = useLocation();
    const isPath = match.path === location.pathname;

    return (
        <Fragment>
            {isPath && <DashboardView match={match} />}

            <Route path={`${match.path}/manufacturers`} component={Manufacturers} />
            <Route exact path={`${match.path}/octaos-apps`} component={OctaosApps} />
            <Route exact path={`${match.path}/octaos-apps/logs`} component={OctaosAppsLogs} />
            <Route path={`${match.path}/run-status-check`} component={RunStatusCheck} />
            <Route path={`${match.path}/mail-logs`} component={MailLogs} />
            <Route path={`${match.path}/sms-logs`} component={SmsLogs} />
            <Route path={`${match.path}/certificates`} component={CertificateInfo} />
            <Route path={`${match.path}/users`} component={Users} />
        </Fragment>
    );
}

export default Dashboard;
