export const getAllSystemsOperational = status => {
    return Object.keys(status).every(key => {
        const value = status[key];

        if (Array.isArray(value)) return getAllSystemsOperational(value);

        if (typeof value === "object" && value.status) return value.status === 200;

        return true;
    })
}