import React, { Fragment } from 'react';
import { EuiText, EuiSpacer, EuiLink } from '@elastic/eui';
import { useHistory } from 'react-router';

import ManufacturerForm from '../Manufacturers/ManufacturerForm';
import useDocument from '../../../../helpers/useDocument';

const EditManufacturer = ({ match }) => {
    const history = useHistory();
    const [loading, error, manufacturer] = useDocument("manufacturers", match.params.manufacturerId);

    if (loading) return <p>Laster...</p>
    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>
    if (!manufacturer) return <p>Produsent ble ikke funnet</p>

    return (
        <Fragment>
            <EuiText>
                <h3>
                    {manufacturer.name}
                    {' '}
                    <EuiLink onClick={() => history.push(`/${manufacturer.statusUrl}`)} >/{manufacturer.statusUrl}</EuiLink>
                </h3>
            </EuiText>

            <EuiSpacer />

            <ManufacturerForm manufacturer={manufacturer} />
        </Fragment>
    );
}

export default EditManufacturer;
