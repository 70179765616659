import React from 'react';
import { Redirect } from "react-router-dom";
import Spinner from '../../../../components/Spinner';

import useUser from '../../../../helpers/useUser';
import NoAccessView from './NoAccessView';

const AuthLayout = ({ match, Component }) => {
    const { user, loading: loadingUser, getCurrentUser, data, loadingData } = useUser();
    const manufacturerUrl = match.params.manufacturerUrl;

    if (!user && !loadingUser && !getCurrentUser()) return <Redirect to={`/${manufacturerUrl}`} />

    const loading = loadingUser || loadingData;

    if (loading) return <Spinner />;

    const hasAccess = data && Array.isArray(data.manufacturers) && data.manufacturers.some(man => man === manufacturerUrl);
    const isAdmin = data && data.isAdmin;

    if (!isAdmin && !hasAccess && user) return <NoAccessView user={user} manufacturerUrl={manufacturerUrl} />;

    return <Component userData={data} manufacturerUrl={manufacturerUrl} />;

}

export default AuthLayout;