import React, { Fragment } from 'react';
import { EuiText } from '@elastic/eui';

import useCollection from '../../../../helpers/useCollection';
import OctaosAppsForm from './OctaosAppsForm';

const OctaosApps = () => {
    const [loading, error, octaosApps] = useCollection("octaosApps");

    if (loading) return <EuiText><p>Laster...</p></EuiText>
    if (error) return <pre>{JSON.stringify(error, null, 4)}</pre>

    return (
        <Fragment>
            <OctaosAppsForm apps={octaosApps} />
        </Fragment>
    );
}

export default OctaosApps;
