
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router';
import { EuiButton } from '@elastic/eui';

import StatusPage from '../StatusPage';

import useUser from '../../../helpers/useUser';

const Home = ({ match }) => {
    const history = useHistory();
    const { user, loading } = useUser();

    const [notFoundManufacturer, setNotFoundManufacturer] = useState(false);

    const url = `/${match.params.manufacturerUrl}`;

    const Button = ({ to, children }) => (
        <EuiButton
            isLoading={loading}
            disabled={loading}
            children={children}
            onClick={() => history.push(`${url}${to}`)}
        />
    )

    return (
        <Fragment>
            <StatusPage match={match} onNotFound={() => setNotFoundManufacturer(true)} />

            {!user && !notFoundManufacturer && (
                <div style={{ textAlign: 'center', marginTop: 64 }} >
                    <Button to="/login" >Request access/Sign in</Button>
                </div>
            )}
        </Fragment>
    );

}

export default Home;