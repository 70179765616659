import React from 'react';

import {
    EuiCallOut,
} from '@elastic/eui';

const PageError = ({ title, message }) => {
    return (
        <EuiCallOut title={title} color="danger" iconType="alert" >
            {message && <p>{message}</p>}
        </EuiCallOut>
    );
};

export default PageError;