import React, { Fragment } from 'react';
import { EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiFlexGroup, EuiFlexItem, EuiText, EuiSkeletonText, EuiHorizontalRule } from '@elastic/eui';
import { useHistory } from 'react-router';

import useCollection from '../../../helpers/useCollection';
import ManufacturerStatus from './ManufacturerStatus';
import { getAllSystemsOperational } from '../../../helpers/utils';
import StatusRow from '../../../components/StatusRow';

const DashboardView = ({ match }) => {
    const history = useHistory();
    const [loadingManufacturers, errorManufacturers, manufacturers] = useCollection("manufacturers");
    const [loadingOctaosApps, errorOctaosApps, statusLogsOctaosApps] = useCollection("octaosAppsStatusLogs", "createdAt");

    const latestStatus = statusLogsOctaosApps ? statusLogsOctaosApps[0] : null;
    const allSysyemsOperational = latestStatus ? getAllSystemsOperational(latestStatus) : false;

    const MenuItem = ({ label, path, icon }) => (
        <EuiKeyPadMenuItem label={label} onClick={() => history.push(`${match.path}${path}`)} >
            <EuiIcon type={icon} size="l" />
        </EuiKeyPadMenuItem>
    )

    return (
        <Fragment>
            <EuiFlexGroup>
                <EuiFlexItem>
                    <EuiKeyPadMenu>
                        <MenuItem label="Produsenter" path="/manufacturers" icon="globe" />
                        <MenuItem label="Octaos Apps" path="/octaos-apps" icon="apps" />
                        <MenuItem label="Kjør status" path="/run-status-check" icon="check" />
                        <MenuItem label="Mail log" path="/mail-logs" icon="email" />
                        <MenuItem label="SMS log" path="/sms-logs" icon="package" />
                    </EuiKeyPadMenu>
                </EuiFlexItem>

                <EuiFlexItem>
                    <EuiText><h3>Produsent status</h3></EuiText>
                    {loadingManufacturers && <EuiSkeletonText lines={5} />}
                    {errorManufacturers && <pre>{JSON.stringify(errorManufacturers, null, 4)}</pre>}
                    {manufacturers && manufacturers.map(manufacturer => (
                        <ManufacturerStatus key={manufacturer.id} manufacturer={manufacturer} />
                    ))}

                    <EuiHorizontalRule />

                    {errorOctaosApps && <pre>{JSON.stringify(errorOctaosApps, null, 4)}</pre>}
                    <StatusRow title="Octaos applikasjoner" status={allSysyemsOperational ? 200 : 500} loading={loadingOctaosApps} />

                </EuiFlexItem>
            </EuiFlexGroup>
        </Fragment>
    );
}

export default DashboardView;
